/*eslint-disable */
import Vue from 'vue'
import moment from 'moment';

Vue.filter('chattimeformat', (arg) => {
  return moment(arg).format('h:mm a')
})
Vue.filter('chatdateformat', (arg) => {
  return moment(arg).format('Do MMMM')
})
Vue.filter('dateformat', (arg) => {
  return moment(arg).format('MMM DD, YYYY')
})
Vue.filter('timeformat', (arg) => {
  return moment(arg).format('h:mm:ss a')
})
Vue.filter('chatfulldateformat', (arg) => {
  return moment(arg).format('MMM DD, YYYY')
})
Vue.filter('UtcDateFormat', (arg) => {
  return moment(arg).utc().format('MMMM DD YYYY')
})
Vue.filter('UtcTimeFormat', (arg) => {
  return moment(arg).utc().format('h:mm:ss a')
})
Vue.filter('LocalDateFormat', (arg) => {
  return moment(arg).local().format('MMM DD, YYYY')
})
Vue.filter('LocalTimeFormat', (arg) => {
  return moment(arg).local().format('h:mm a')
})