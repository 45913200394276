/*eslint-disable */

import Router from 'vue-router'
import Vue from 'vue'
import store from './store/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  canReuse: false,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  },
  routes: [
    {
      path: '/',
      component: () => import( /* webpackChunkName: "MainHome" */ './view/Public/publicHome.vue'),
      beforeEnter: (to, from, next) => {
        next();
      },
      children: [
        {
          path: '/',
          redirect: {
            name: 'MainHome'
          }
        },
        {
          path: '/',
          name: 'MainHome',
          component: () => import( /* webpackChunkName: "MainHome" */ './view/Public/landing/index.vue'),
          guest: true,
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - Home',
              next();
          },
        },
        {
          path: '/login/',
          name: 'LoginPage',
          component: () => import( /* webpackChunkName: "Login" */ './view/Authentication/login/index.vue'),
          guest: true,
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - Login',
              next();
          }
        },
        {
          path: '/signup/',
          name: 'SignupPage',
          component: () => import( /* webpackChunkName: "Signup" */ './view/Authentication/signup/index.vue'),
          guest: true,
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - Signup',
              next();
          }
        },
      ]
    },
    {
      path: '/user/agreement/',
      name: 'userAgreement',
      component: () => import( /* webpackChunkName: "userAgreement" */ './view/Public/user-agreement/index.vue'),
      guest: true,
      beforeEnter: (to, from, next) => {
        document.title = 'LBIL - User Agreement',
          next();
      }
    },
    {
      path: '/privacy-policy/',
      name: 'privacyPolicy',
      component: () => import( /* webpackChunkName: "privacyPolicy" */ './view/Public/privacy-policy/index.vue'),
      guest: true,
      beforeEnter: (to, from, next) => {
        document.title = 'LBIL - Privacy Policy',
          next();
      }
    },
    {
      path: '/contact-us/',
      name: 'contactUs',
      component: () => import( /* webpackChunkName: "contactUs" */ './view/Public/contact-us/index.vue'),
      guest: true,
      beforeEnter: (to, from, next) => {
        document.title = 'LBIL - Contact Us',
          next();
      }
    },
    {
      path: '/about-us/',
      name: 'aboutUs',
      component: () => import( /* webpackChunkName: "aboutUs" */ './view/Public/about-us-page/index.vue'),
      guest: true,
      beforeEnter: (to, from, next) => {
        document.title = 'LBIL - About Us',
          next();
      }
    },
    {
      path: '/dashboard/',
      component: () => import( /* webpackChunkName: "Dashboard" */ './view/Private/dashboard/index.vue'),
      beforeEnter: (to, from, next) => {
        next();
      },
      children: [
        {
          path: '/',
          redirect: {
            name: 'DashboardMain'
          }
        },
        {
          path: '/dashboard/',
          name: 'DashboardMain',
          component: () => import( /* webpackChunkName: "DashboardMain" */ './view/Private/dashboard-main/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - Dashboard'
            next();
          }
        },
        {
          path: '/consult/schedule/form/',
          name: 'consultScheduleForm',
          component: () => import( /* webpackChunkName: "consultScheduleForm" */ './view/Private/update-scheduling-form/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - Consult Form'
            next();
          }
        },
        {
          path: '/video/conference/',
          name: 'videoConference',
          component: () => import( /* webpackChunkName: "videoConference" */ './view/Private/conference/one-to-one-call/index.vue'),
          guest: true,
          beforeEnter: (to, from, next) => {
              next();
            document.title = 'LBIL - Video Conference'
          }
        },
        {
          path: '/video/conference/left/room/',
          name: 'videoConferenceLeftRoom',
          component: () => import( /* webpackChunkName: "videoConferenceLeftRoom" */ './view/Private/conference/direct-call-from-user-left/index.vue'),
          guest: true,
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - Video User Left Room',
            next();
          }
        },
        {
          path: '/consult/schedule/form/success/',
          name: 'consultScheduleFormSuccess',
          component: () => import( /* webpackChunkName: "consultScheduleFormSuccess" */ './view/Private/update-scheduling-form/confirmationFormSubmittion.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - Consult Form'
            next();
          }
        },
        {
          path: '/consult/schedule/form/update/success/',
          name: 'consultScheduleFormUpdateSuccess',
          component: () => import( /* webpackChunkName: "consultScheduleFormUpdateSuccess" */ './view/Private/update-scheduling-form/confirmationFormUpdateSubmittion.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - Consult Form'
            next();
          }
        },
        {
          path: '/update/profile/',
          name: 'updateProfile',
          component: () => import( /* webpackChunkName: "updateProfile" */ './view/Private/updatedProfile/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - Update Profile'
            next();
          }
        },
        {
          path: '/patient/appointments/',
          name: 'patientAppointments',
          component: () => import( /* webpackChunkName: "patientAppointments" */ './view/Private/appointment/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            store.dispatch('getPatientsListAction')
            document.title = 'LBIL - Patients Appointments'
            next();
          }
        },
        {
          path: '/patients/list/',
          name: 'patientsList',
          component: () => import( /* webpackChunkName: "patientsList" */ './view/Private/patients/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - Patients List'
            next();
          }
        },
        {
          path: '/patient/messages/',
          name: 'patientMessages',
          component: () => import( /* webpackChunkName: "patientMessages" */ './view/Private/patient-message-list/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - Patient Messages'
            next();
          }
        },
        {
          path: '/patient/records/',
          name: 'patientRecords',
          component: () => import( /* webpackChunkName: "patientRecords" */ './view/Private/patient-health-record/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - Patient Records'
            next();
          }
        },
        {
          path: '/patient/feedbacks/',
          name: 'patientFeedbacks',
          component: () => import( /* webpackChunkName: "patientFeedbacks" */ './view/Private/patient-feedbacks/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - Patient Feedbacks'
            next();
          }
        },
        {
          path: '/notifications/',
          name: 'notifications',
          component: () => import( /* webpackChunkName: "notifications" */ './view/Private/notifications/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - Notifications'
            next();
          }
        },
        {
          path: '/payment/',
          name: 'payments',
          component: () => import( /* webpackChunkName: "payments" */ './view/Private/payment/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            store.dispatch('getPaymentHistoryFunc');
            document.title = 'LBIL - Payment';
            next();
          }
        },
        {
          path: '/consult/workspace/:consultID',
          name: 'consultWorkspace',
          component: () => import( /* webpackChunkName: "consultWorkspace" */ './view/Private/consult-workspace/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            // store.dispatch('getPatientsListAction')
            store.dispatch('getconsultPatientDetailByIdAction', {id:to.params.consultID})
            document.title = 'LBIL - Consult Workspace'
            next();
          }
        },
      ]
    }
  ]
})
// Routes use for without login. these are the name of routes not path
const openRoutes = ['MainHome', 'userAgreement', 'privacyPolicy', 'contactUs', 'aboutUs', 'LoginPage', 'SignupPage']

// Routes use for without login
router.beforeEach((to, from, next) => {
  if (openRoutes.includes(to.name)) {
    next();
  } else if (store.getters.isAuthenticated && localStorage.getItem('token') != '' && localStorage.getItem('token') != null && localStorage.getItem('LoginedUserID') != '' && localStorage.getItem('LoginedUserID') != null) {
    next()
  } else {
    let redirectTo = to.path;
    let redirectToHash = to.hash;
    let redirectToFull = '?redirect=' + redirectTo + redirectToHash;
    next('/' + redirectToFull)
  }
})

/** Show loader when moving routes */
router.beforeResolve((to, from, next) => {
  if (to.name) {
    if (openRoutes.includes(to.name)) {
      document.getElementById("preloader").style.display = "flex";
    }
    next();
  }
});

/** Hide preloader after each routes */
router.afterEach((to, from) => {
  if (openRoutes.includes(to.name)) {
    setTimeout(() => {
      document.getElementById("preloader").style.display = "none";
    }, 200);
  }
});
export default router;