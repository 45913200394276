/*eslint-disable */

import Vue from 'vue'
import axios from 'axios';
import root from '@/_helpers/roots';

let selfUserInfoApiUrl = root.selfUserInfoApiUrl.link;

export default {
  state: {
    selfUserDetailState: {},
  },
  mutations: {
    selfUserDetailMutations(state, value) {
      Vue.set(state, 'selfUserDetailState', value);
    },
  },
  actions: {
    // Get Self User Info Action Function
    getSelfUserInfoApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(selfUserInfoApiUrl, payload).then(response => {
            context.commit('selfUserDetailMutations', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get Self User Info Action Function
    selfUserInfoUpdateAction(context, payload) {
        return new Promise((resolve, reject) => {
            axios.patch(selfUserInfoApiUrl, payload).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
  },
  getters: {
    selfUserDetailGetters : state => state.selfUserDetailState
  }
}