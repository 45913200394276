/*eslint-disable */

import Vue from 'vue'
import axios from 'axios';
import root from '@/_helpers/roots';

let patientListApiUrl = root.patientListApiUrl.link;
let patientInfoListApiUrl = root.patientInfoListApiUrl.link;
let patientOneTimeMessageListApiUrl = root.patientOneTimeMessageListApiUrl.link;
let patientMessageReplyListApiUrl = root.patientMessageReplyListApiUrl.link;
let updateConsultAppointmentDetailApiUrl = root.appointmentStatusUpdateApiUrl.link;
let videoSendToPatientApiUrl = root.videoSendToPatientApiUrl.link;
let consentFormUpdateApiUrl = root.consentFormUpdateApiUrl.link;

export default {
  state: {
    getconsultPatientState:{},
    getconsultPatientByIdState:{},
    // getNotesOfPatientState:{},
    // getRemarksOfPatientState:{},
    // getPrescriptionOfPatientState:{}
  },
  mutations: {
    getconsultPatientMutation(state, value) {
      Vue.set(state, 'getconsultPatientState', value);
    },
    getconsultPatientByIdMutation(state, value) {
      Vue.set(state, 'getconsultPatientByIdState', value);
    },
    // getNotesOfPatientMutation(state, value) {
    //   Vue.set(state, 'getNotesOfPatientState', value);
    // },
    // getRemarksOfPatientMutation(state, value) {
    //   Vue.set(state, 'getRemarksOfPatientState', value);
    // },
    // getPrescriptionOfPatientMutation(state, value) {
    //   Vue.set(state, 'getPrescriptionOfPatientState', value);
    // }
  },
  actions: {
    // Get Consent Form Info By Id Action
    consentFormInfoByIdAction(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(consentFormUpdateApiUrl + payload.id + '/').then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // // Get Patient One Time Messages List Action
    replyOnPatientMsgMethod(context, payload) {
        return new Promise((resolve, reject) => {
            axios.patch(patientOneTimeMessageListApiUrl + payload.data.id + '/', payload.data.data).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get Patient One Time Messages List Action
    getPatientsOneTimeMsgListAction(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(patientOneTimeMessageListApiUrl).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get Patient List By Filter Action
    getPatientsListByFilterAction(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(patientListApiUrl + '?status=' + payload.filter).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get Patient List Action
    getPatientsListAction(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(patientListApiUrl).then(response => {
            context.commit('getconsultPatientMutation', response);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get Patient Info By ID List Action
    getconsultPatientDetailByIdAction(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(patientInfoListApiUrl + payload.id + '/').then(response => {
              context.commit('getconsultPatientByIdMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    updateAppointmentDetailsMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios.patch(updateConsultAppointmentDetailApiUrl + payload.data.id + '/', payload.data.data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    updateVideoForPatientMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios.patch(videoSendToPatientApiUrl + payload.data.id + '/', payload.data.data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
  },
  getters: {
    getconsultPatientGetters: state => state.getconsultPatientState,
    getconsultPatientByIdGetters: state => state.getconsultPatientByIdState,
    // getNotesOfPatientGetters : state => state.getNotesOfPatientState,
    // getRemarksOfPatientGetters : state => state.getRemarksOfPatientState,
    // getPrescriptionOfPatientGetters : state => state.getPrescriptionOfPatientState
  }
}