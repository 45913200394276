/*eslint-disable */

import Vue from 'vue'
import axios from 'axios';
import root from '@/_helpers/roots';

let doctorReviewsApiUrl = root.doctorReviewsApiUrl.link;

export default {
  state: {
  },
  mutations: {
  },
  actions: {
    // Get Doctor Reviews List Action
    getDoctorReviewsListAction(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(doctorReviewsApiUrl, payload).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
  },
  getters: {
  }
}