/*eslint-disable */

import Vue from 'vue'
import axios from 'axios';
import root from '@/_helpers/roots';

let getPaymentHistoryApiURL = root.getPaymentHistoryApiUrl.link;

export default {
  state: {
    getPaymentHistoryState:{},
  },
  mutations: {
    getPaymentHistoryMutation(state, value) {
      Vue.set(state, 'getPaymentHistoryState', value);
    },
  },
  actions: {
    // Get Payment History Function
    getPaymentHistoryFunc(context, payload) {
        return new Promise((resolve, reject) => {
          axios.get(getPaymentHistoryApiURL).then(response => {
            context.commit('getPaymentHistoryMutation', response);
            resolve(response);
          }).catch(err => {
            reject(err);
          })
        })
    },
  },
  getters: {
    getPaymentHistoryGetters : state => state.getPaymentHistoryState,
  }
}