/*eslint-disable */
let BaseURL = process.env.VUE_APP_API_BASE_URL;
let consultRoots = {
// Consult Patient Notes Url
consultPatientNotesApiUrl: {
    "link": BaseURL + "api/account/consult/doctor/notes/"
},
// Remark For Patient Api Url
remoarkForPatientApiUrl: {
    "link": BaseURL + "api/account/consult/doctor/remark/"
},
// Prescription For Patient Api Url
prescriptionForPatientApiUrl: {
    "link": BaseURL + "api/account/consult/get_prescription/"
},
addPrescriptionApiUrl:{
    "link": BaseURL + "api/account/consult/prescription/"
},
patientPrescriptionReCreatedApiUrl:{
    "link": BaseURL + "api/account/consult/prescription/"
},
updatePrescriptionApiUrl:{
    "link": BaseURL + "api/account/consult/prescription_patch/"
},
}
export default consultRoots;