/*eslint-disable */

import {bus} from '@/main';

export default {
  name: 'before-login-header',
  components: {},
  props: [],
  data () {
    return {
      showBtn:true,
      logoHideState:true
    }
  },
  computed: {

  },
  mounted () {
    bus.$on('showHeaderSection', (data) => {
      this.showBtn = data;
    });

    bus.$on('headerDisabledBus', (data) => {
      this.logoHideState = data
  })
  },
  methods: {
  },
}


