/*eslint-disable */

import footer from '@/components/common/footer/';
import header from '@/components/common/header/index.vue';

export default {
  name: 'app',
  components: {
    'app-header':header,
    'app-footer' : footer,
  },
  props: [],
  data () {
    return {
    }
  },
  computed: {

  },
  mounted () {
  },
  methods: {

  }
}