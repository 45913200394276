/*eslint-disable */

import Vue from 'vue'
import axios from 'axios';
import root from '@/_helpers/roots';

let doctorCategoriesApiUrl = root.doctorCategoriesApiUrl.link;
let doctorSchedulePostApiUrl = root.doctorSchedulePostApiUrl.link;
let consultCurrenciesApiUrl = root.consultCurrenciesApiUrl.link;
let doctorScheduleGetApiUrl = root.doctorScheduleGetApiUrl.link;
let patientWithChatRoomDetailApiUrl = root.patientWithChatRoomDetailApiUrl.link;
let uploadLicenseFileOfDoctorApiUrl = root.uploadLicenseFileOfDoctorApiUrl.link;
let doctorSchedulePatchApiUrl = root.doctorSchedulePatchApiUrl.link;
let uploadESignedSignatureApiUrl = root.uploadESignedSignatureApiUrl.link;

export default {
  state: {
  },
  mutations: {
  },
  actions: {
    // Get Doctor Consult Info Action
    updateDoctorConsultInfoAction(context, payload) {
        return new Promise((resolve, reject) => {
            axios.patch(doctorSchedulePatchApiUrl, payload).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    getESignedSignatureMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios.get(uploadESignedSignatureApiUrl).then((response) => {
              resolve(response);
            }).catch((error) => {reject(error);})
      })
    },
    uploadESignedSignatureMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios.patch(uploadESignedSignatureApiUrl, payload).then((response) => {
              resolve(response);
            }).catch((error) => {reject(error);})
      })
    },
    uploadLicenseFileOfDoctorMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios.patch(uploadLicenseFileOfDoctorApiUrl, payload).then((response) => {
              resolve(response);
            }).catch((error) => {reject(error);})
      })
    },
    getChatRoomDetailForPatientMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios.get(patientWithChatRoomDetailApiUrl + payload.id + '/')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    // Get Doctor Consult Info Action
    getDoctorConsultInfoAction(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(doctorScheduleGetApiUrl, payload).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get Consult Currencies Action
    getConsultCurrenciesAction(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(consultCurrenciesApiUrl, payload).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get Doctor Categories Action
    getDoctorCategoriesAction(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(doctorCategoriesApiUrl, payload).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Post Doctor Schedule Form Action
    postDoctorScheduleAction(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post(doctorSchedulePostApiUrl, payload).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
  },
  getters: {
  }
}