/*eslint-disable */

import axios from 'axios';
import root from '@/_helpers/roots';

let authLoginApiUrl = root.authLoginApiUrl.link;
let authLoginVerifyApiUrl = root.authLoginVerifyApiUrl.link;
let authSignupApiUrl = root.authSignupApiUrl.link;
let authSignupVerifyApiUrl = root.authSignupVerifyApiUrl.link;
let userLogoutApiUrl = root.logoutApiUrl.link;
let OtpSendOnMobileApiUrl = root.OtpSendOnMobileApiUrl.link;

export default {
  state: {
    token: localStorage.getItem('token') || '',
    status: '',
  },
  mutations: {
    authSuccess(state, token) {
      state.token = token;
      state.status = 'success';
    },
    authError(state) {
      state.token = '';
      state.status = 'error';
    },
    authLogout(state) {
      state.token = '';
      state.status = '';
    },
  },
  actions: {
    // Send Otp On Mobile Function
    optCreateOnMobileApiFun(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post(OtpSendOnMobileApiUrl, payload).then(response => {
            resolve(response);
          }).catch(err => {
            reject(err);
          })
        })
    },
    // Resend Login Request Verify Action
    resentOtpForLoginAction(context, payload) {
      return new Promise((resolve, reject) => {
        axios.put(authLoginApiUrl, payload).then(
            (response) => {
              resolve(response);
            }
          )
          .catch((error) => {
            reject(error);
          })
      })
    },
    // Login Request Verify Action
    requestOtpForLoginAction(context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(authLoginApiUrl, payload).then(
            (response) => {
              resolve(response);
            }
          )
          .catch((error) => {
            reject(error);
          })
      })
    },
    // Login OTP Verify Action
    loginOtpVerifyAction(context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(authLoginVerifyApiUrl, payload)
          .then((response) => {
            context.commit('authSuccess', response.data.data.token);
            localStorage.setItem('LoginedUserID', response.data.data.user.id);
            localStorage.setItem('LoginedUserInfo', JSON.stringify(response.data.data.user));
            localStorage.setItem('token', response.data.data.token);
            axios.defaults.headers.common['Authorization'] = "Token " + response.data.data.token;
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            resolve(response);
          })
          .catch((error) => {
            localStorage.removeItem('token');
            context.commit('authError');
            reject(error);
          })
      })
    },
    // Signup API With Request OTP Action
    authUserSignupApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post(authSignupApiUrl, payload).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        })
    },
    // Signup API With Request OTP Action
    resendReqSignupAction(context, payload) {
        return new Promise((resolve, reject) => {
            axios.put(authSignupApiUrl, payload).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        })
    },
    // Signup Verify With OTP API Action
    signUpVerifyAction(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post(authSignupVerifyApiUrl, payload).then(response => {
              resolve(response);
              context.commit('authSuccess', response.data.data.token);
              localStorage.setItem('LoginedUserInfo', JSON.stringify(response.data.data.user));
              localStorage.setItem('LoginedUserID', response.data.data.user.id);
              localStorage.setItem('token', response.data.data.token);
              axios.defaults.headers.common['Authorization'] = "Token " + response.data.data.token;
              axios.defaults.headers.post['Content-Type'] = 'application/json';
              resolve(response);
            }).catch(err => {
                reject(err);
            })
        })
    },
    // Logout Action
    logout(context) {
      return new Promise((resolve, reject) => {
        context.commit('authLogout');
        localStorage.removeItem('token');
        localStorage.removeItem('LoginedUserID');
        localStorage.removeItem('LoginedUserInfo');
        localStorage.removeItem('userAgreementState');
        delete axios.defaults.headers.common['Authorization'];
        resolve();
      })
    },
    // Token Header Set Action
    getAuthHeader() {
      return {
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    },
    // Otp Verify On Mobile Action
    optVerifyOnMobileApiFun(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post(loginOtpUrl, payload).then(response => {
            resolve(response);
            context.commit('authSuccess', response.data.data.token);
            localStorage.setItem('LoginedUserInfo', JSON.stringify(response.data.data.user));
            localStorage.setItem('LoginedUserID', response.data.data.user.id);
            localStorage.setItem('token', response.data.data.token);
            axios.defaults.headers.common['Authorization'] = "Token " + response.data.data.token;
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            resolve(response);
          }).catch(err => {
            reject(err);
          })
        })
    },
  },
  getters: {
    isAuthenticated: state => !!state.token && !!localStorage.getItem('LoginedUserID'),
    authStatus: state => state.status,
  }
}