/*eslint-disable */
export default {
  name: 'app_footer',
  components: {},
  props: [],
  data () {
    return {
      year: new Date().getFullYear()
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}


