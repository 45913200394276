/*eslint-disable */

import Vue from 'vue';
import Vuex from 'vuex';
import authStore from './authStore';
import consultActionStore from './consultActionStore';
import doctorAppointmentsStore from './doctorAppointmentsStore';
import doctorConsultStore from './doctorConsultStore';
import doctorFeedbackStore from './doctorFeedbackStore';
import paymentStore from './paymentStore';
import userStore from './userStore';

export const strict = false

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    authStore,
    userStore,
    doctorConsultStore,
    doctorFeedbackStore,
    doctorAppointmentsStore,
    consultActionStore,
    paymentStore
  }
})