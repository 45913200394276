<template>
    <div class="mbl_menubar_wrp ml-auto" v-click-outside="menuBarClosedMehtod">
        <span class="menubaar_icon">
            <template v-if="mobileMenuBaar">
                <i class="fa fa-times" aria-hidden="true" style="color:#fe4a3b;" @click="menuBarClosedMehtod"></i>
            </template>
            <template v-else>
                <i class="fa fa-bars" aria-hidden="true" @click="menuBarOpenMehtod"></i>
            </template>
        </span>
        <div class="menubaar_list_wrp" :class="mobileMenuBaar ? 'active' : false">
            <div class="menubar_tabs_list_wrp">
                <ul class="menubaar_list">
                    <li @click="menuBarClosedMehtod">
                        <router-link :to="{ name: 'updateProfile' }" class="nav-link">Update Profile</router-link>
                    </li>
                    <li @click="menuBarClosedMehtod">
                        <router-link :to="{ name: 'patientAppointments' }" class="nav-link">Appointments</router-link>
                    </li>
                    <li @click="menuBarClosedMehtod">
                        <router-link :to="{ name: 'consultScheduleForm' }" class="nav-link">Consult Form</router-link>
                    </li>
                    <li @click="menuBarClosedMehtod">
                        <router-link :to="{ name: 'payments' }" class="nav-link">Payment</router-link>
                    </li>
                    <li @click="menuBarClosedMehtod">
                        <router-link :to="{ name: 'DashboardMain' }" class="nav-link">Dashboard</router-link>
                    </li>
                    <li @click="menuBarClosedMehtod">
                        <router-link :to="{ name: 'patientMessages' }" class="nav-link">Patient Messages</router-link>
                    </li>
                    <li @click="menuBarClosedMehtod">
                        <router-link :to="{ name: 'patientFeedbacks' }" class="nav-link">Patient
                            Feedbacks</router-link>
                    </li>
                    <li @click="menuBarClosedMehtod">
                        <router-link :to="{ name: 'notifications' }" class="nav-link">Notifications</router-link>
                    </li>
                    <li class="dropdown_item"><a href="javascript:void(0)" @click="logout"
                            class="nav-link">Logout</a></li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
/*eslint-disable */
export default {
    name: 'mobile-menu-bar',
    components: {},
    props: [],
    data() {
        return {
            mobileMenuBaar: false,
            subMblMenuState: 'home',
            isMobile: false,
            notificationBadgeInfo: null
        }
    },
    computed: {
    },
    mounted() {
    },
    methods: {
        menuBarOpenMehtod() {
            this.mobileMenuBaar = true
            document.body.classList.add('body_right_scroll_remove')
        },
        menuBarClosedMehtod() {
            this.mobileMenuBaar = false
            this.subMblMenuState = 'home'
            document.body.classList.remove('body_right_scroll_remove')
        },
        subMblMenuMethod(data) {
            this.subMblMenuState = data
        },
        subMblMenuClosedMethod() {
            this.subMblMenuState = ''
        },
        logOutUser() {
            if ('BroadcastChannel' in self) {
                authChannel.postMessage({
                    cmd: 'logout'
                })
            }
        },
        logout() {
            this.$store.dispatch('logout')
                .then(() => {
                    this.logOutUser()
                    window.location.href = '/';
                })
        },
    }
}
</script>

<style lang="scss" scoped>
.mbl_menubar_wrp {
    .menubaar_icon {
        font-size: 28px;
        cursor: pointer;
        color: #ffd400;
    }

    .menubaar_list_wrp {
        position: fixed;
        left: -100%;
        background: rgba(10, 10, 10, 0.96);
        top: 0;
        bottom: 0;
        height: 100%;
        width: calc(100% - 40px);
        transition: all ease 0.5s;
        -webkit-transition: all ease 0.5s;
        z-index: 10001;

        // &::before {
        //     content: "";
        //     background: rgba(255, 255, 255, 0.1);
        //     width: 60px;
        //     height: 100%;
        //     position: absolute;
        // }

        &.active {
            left: 0px;
        }

        .menubaar_list {
            margin: 0px;
            padding: 0px;
            text-align: left;
            list-style: none;
            li {
                padding:5px 10px;
                a {
                    font-size: 16px;
                    color: #b0b0b0;
                    letter-spacing: 0.5px;
                    padding: 8px 20px;
                    line-height: 1.3;
                    border-radius: 4px;
                    border: 1px solid #a560e8;
                    display: inline-block;
                    min-width: 190px;
                    text-align: center;

                    &:hover {
                        color: #ffd400;
                    }

                    &.router-link-active {
                        color: #ffd400;
                    }
                }
            }
        }
    }

    .menubar_tabs_list_wrp {
        padding: 25px 0;
        position: relative;
        height: 100vh;
        overflow-y: auto;

        .menubar_tabs_list {
            margin: 0;

            &>li {
                // background: rgba(255, 255, 255, 0.1);
                text-align: center;
                font-size: 34px;
                color: #ffd400;
                width: 60px;
                height: 80px;
                line-height: 80px;
                // margin-bottom: 1px;
                // position: relative;
                border-bottom: 1px solid rgba(0, 0, 0, 0.34);

                &.active_li {
                    background: #87cefa;
                    color: #000;

                    .menubaar_list {
                        display: block;
                    }
                }
            }
        }
    }
}
</style>