<template>
    <header class="header_fix header">
        <header-after-login v-if="this.$store.getters.isAuthenticated"></header-after-login>
        <header-before-login v-else></header-before-login>
    </header>
</template>


<script>
/*eslint-disable */
import { bus } from '@/main';
import headerBeforeLogin from './before-login-header/index';
import headerAfterLogin from './after-login-header/index';
export default {
    name: 'app_header',
    components: {
        'header-before-login': headerBeforeLogin,
        'header-after-login': headerAfterLogin
    },
    props: [],
    data() {
        return {
            headerDisabled:true
        }
    },
    computed: {

    },
    mounted() {
    bus.$on('headerDisabledBus', (data) => {
        this.headerDisabled = data
    })
    },
    methods: {
        // handleScroll() {
        //   let header = document.querySelector(".header_sticky");
        //   if (window.scrollY > 500 && !header.className.includes('header_is_sticky')) {
        //     header.classList.add('header_is_sticky'); 
        //   }else if (window.scrollY < 500) {
        //       header.classList.remove('header_is_sticky');
        //   }
        // },
    },
    watch: {
        checkUserLogin() {
            return this.$store.getters.isAuthenticated;
        }
    },
    created() {
        // window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        // window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>
<style lang="scss" scoped>
.header{
    // padding: 10px 15px 5px;
    // background: rgba(0, 0, 0, .5);
    position: relative;
    z-index:10;
}
</style>